import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { Flex, Text } from '@chakra-ui/react';

import { useGlobalState } from '@people/ui/shared/hooks';

type TSidebarNavLinkProps = {
  path: string;
  label: string;
  icon: React.ReactNode;
};

export const SidebarNavLink: React.FC<TSidebarNavLinkProps> = ({ path, label, icon }) => {
  const { id } = useParams();
  const location = useLocation();
  const { sidebarOpen } = useGlobalState();
  const [isActive, setIsActive] = React.useState<string>('');

  React.useEffect(() => {
    if (id) {
      let pathname = location.pathname.replace(`/${id}`, '');
      const isEditing = pathname.includes('/editing');
      if (isEditing) {
        pathname = pathname.replace(`/editing`, '');
      }
      setIsActive(pathname);
    } else {
      setIsActive(location.pathname);
    }
  }, [location, path, id]);

  return (
    <Flex
      h={8}
      gap={3}
      w="100%"
      to={path}
      as={NavLink}
      bg="transparent"
      fontSize="sm"
      paddingInline={2}
      borderRadius="md"
      position="relative"
      whiteSpace="nowrap"
      alignItems="center"
      justifyContent={!sidebarOpen ? 'center' : 'flex-start'}
      border="1px solid transparent"
      className={isActive === path ? 'active-link' : ''}
      transition="background-color 0.2s, border-color 0.2s, background 0.2s, color 0.2s"
      _hover={{ bg: 'gray.200' }}
      sx={{
        '&.active-link': {
          bg: 'rgb(37 35 135 / 80%)',
          color: 'white',
          '&:hover': {
            bg: 'rgb(37 35 135)'
          }
        }
      }}>
      {icon}
      <Text display={!sidebarOpen ? 'none' : 'block'}>{label}</Text>
    </Flex>
  );
};
