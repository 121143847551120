import { Button, Flex, Tooltip } from '@chakra-ui/react';

interface NavbarButtonProps {
  show?: boolean;
  label?: string;
  hasArrow?: boolean;
  flexGap?: number;
  onClick?: () => void;
  width?: string | number;
  bgColor?: string;
  justifyContent?: string;
  hoverBgColor?: string;
  children: React.ReactNode;
  paddingInline?: number;
}

export const NavBarButton: React.FC<NavbarButtonProps> = ({
  show,
  label,
  width,
  onClick,
  children,
  hasArrow,
  hoverBgColor,
  justifyContent,
  paddingInline,
  bgColor,
  flexGap
}) => {
  const buttonElement = (
    <Flex
      gap={flexGap || 2}
      h={8}
      minH={8}
      maxH={8}
      w={width || 8}
      minW={width || 8}
      maxW={width || 8}
      as={Button}
      padding={0}
      paddingInline={paddingInline || 0}
      onClick={onClick}
      color="inherit"
      bg={bgColor || 'transparent'}
      borderRadius="md"
      whiteSpace="nowrap"
      position="relative"
      alignItems="center"
      justifyContent={justifyContent || 'center'}
      border="1px solid transparent"
      _hover={{ bg: hoverBgColor || 'rgba(255, 255, 255, 0.1)' }}>
      {children}
    </Flex>
  );

  return show ? (
    <Tooltip hasArrow={hasArrow} borderRadius="md" fontSize="sm" label={label} placement="bottom">
      {buttonElement}
    </Tooltip>
  ) : (
    buttonElement
  );
};
