import React from 'react';

import { useGlobalStateAndDispatch } from '@people/ui/shared/hooks';
import { TGlobalReducerAction, TGlobalState, TSEOProps } from '@people/ui/shared/interfaces';

import { GlobalReducerActionType } from '../enums';

/**
 * @public
 */

export interface TGlobalContextProps extends TGlobalState {
  globalDispatch: React.Dispatch<TGlobalReducerAction>;
}

/**
 * @public
 */

export const GlobalContext = React.createContext<TGlobalContextProps | undefined>(undefined);

GlobalContext.displayName = 'GlobalContext';

/**
 * @public
 */

export type TGlobalStateProviderProps = {
  children?: React.ReactNode;
};

/**
 * @public
 */

export const GlobalStateProvider = (props: TGlobalStateProviderProps): JSX.Element => {
  const hasMounted = React.useRef(false);
  const { globalState, globalDispatch } = useGlobalStateAndDispatch();

  React.useEffect(() => {
    if (hasMounted.current) {
      return;
    }
    hasMounted.current = true;
  }, [globalState]);

  return (
    <GlobalContext.Provider value={{ ...globalState, globalDispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

/**
 * @public
 */

export const GlobalReducer = (state: TGlobalState, action: TGlobalReducerAction): TGlobalState => {
  switch (action.type) {
    case GlobalReducerActionType.SET_MY_CLAIM_STAT:
      const myClaimStatState = {
        ...state,
        myClaimStat: action.myClaimStat
      };
      localStorage.setItem('globalState', JSON.stringify(myClaimStatState));
      return myClaimStatState;
    case GlobalReducerActionType.SET_CATEGORIES:
      const claimCategoriesState = {
        ...state,
        claimCategories: action.claimCategories
      };
      localStorage.setItem('globalState', JSON.stringify(claimCategoriesState));
      return claimCategoriesState;
    case GlobalReducerActionType.SET_CURRENCIES:
      const currenciesState = {
        ...state,
        currencies: action.currencies
      };
      localStorage.setItem('globalState', JSON.stringify(currenciesState));
      return currenciesState;
    case GlobalReducerActionType.SET_SIDEBAR_OPEN:
      const sidebarState = {
        ...state,
        sidebarOpen: action.sidebarOpen
      };
      localStorage.setItem('globalState', JSON.stringify(sidebarState));
      return sidebarState;
    case GlobalReducerActionType.SET_SEO:
      const seoState = {
        ...state,
        seo: {
          ...Object.fromEntries(Object.entries(action.seo).filter(([_, v]) => v !== undefined))
        } as TSEOProps
      };
      localStorage.setItem('globalState', JSON.stringify(seoState));
      return seoState;
    default:
      const defaultState = { ...state };
      localStorage.setItem('globalState', JSON.stringify(defaultState));
      return defaultState;
  }
};
