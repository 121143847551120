/**
 * @public
 */

export const normalizeErrorFn =
  (fallbackMessage: string) =>
  (error: unknown): Error => {
    if (error instanceof Error) {
      return error;
    }
    return new Error(fallbackMessage);
  };

/**
 * @public
 */

export const signInError = normalizeErrorFn('Sign in failed');

/**
 * @public
 */

export const signOutError = normalizeErrorFn('Sign out failed');

/**
 * @public
 */

export const clearCookies = () => {
  document.cookie = 'access_token_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'refresh_token_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'csrf_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'csrf_refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = '';
};

/**
 * @public
 */

export function isInBrowser() {
  return (
    typeof window !== 'undefined' &&
    typeof window.document !== 'undefined' &&
    typeof window.document.createElement !== 'undefined'
  );
}

/**
 * @public
 */

export function hasCookies() {
  return document.cookie.length > 0;
}
