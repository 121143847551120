import React from 'react';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri';

import { Flex, Heading, Icon, useBreakpointValue } from '@chakra-ui/react';

import { NavBarButton } from '@people/ui/shared/components/buttons';
import { GlobalReducerActionType } from '@people/ui/shared/enums';
import { useGlobalState } from '@people/ui/shared/hooks';

type TPageHeadProps = {
  title: string;
  hasSidebar?: boolean;
  icon: React.ReactNode;
  actionsArea?: React.ReactNode;
};

export const PageHead: React.FC<TPageHeadProps> = ({ title, icon, actionsArea, hasSidebar }) => {
  const { sidebarOpen, globalDispatch } = useGlobalState();
  const isSmallScreens = useBreakpointValue({ base: true, md: false }, { ssr: false });
  return (
    <Flex
      w="100%"
      height={10}
      flexShrink={0}
      paddingInline={4}
      position="relative"
      borderBottomWidth={1}
      flexDirection="column">
      <Flex
        height={8}
        marginTop={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Flex gap={4} alignItems="center">
          {hasSidebar && !isSmallScreens && (
            <Flex alignItems="center">
              <NavBarButton
                hoverBgColor="gray.200"
                onClick={() =>
                  globalDispatch({
                    sidebarOpen: !sidebarOpen,
                    type: GlobalReducerActionType.SET_SIDEBAR_OPEN
                  })
                }>
                {sidebarOpen ? (
                  <Icon as={RiMenuFoldLine} w={5} h={5} color="inherit" />
                ) : (
                  <Icon as={RiMenuUnfoldLine} w={5} h={5} color="inherit" />
                )}
              </NavBarButton>
            </Flex>
          )}
          <Flex gap={2} alignItems="center">
            {icon}
            <Heading fontSize="sm">{title}</Heading>
          </Flex>
        </Flex>
        <Flex alignItems="center">{actionsArea && actionsArea}</Flex>
      </Flex>
    </Flex>
  );
};
