import React from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { RiNotification3Line } from 'react-icons/ri';
import { TbCategory2 } from 'react-icons/tb';

import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList
} from '@chakra-ui/react';

import { QuickAccessLink } from '@people/ui/shared/components/links';
import { appModuleLinks } from '@people/ui/shared/constants';

export const NavBarQuickAccessMenu: React.FC = () => {
  return (
    <Box role="presentation" position="relative">
      <Menu>
        <MenuButton
          h={8}
          w={8}
          padding={0}
          borderRadius="md"
          paddingInline={0}
          alignItems="center"
          justifyContent="center"
          transition="background-color 0.2s"
          _hover={{ bg: 'rgba(255, 255, 255, 0.1)' }}
          sx={{
            '& span': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}>
          <Icon as={TbCategory2} w={5} h={5} color="inherit" />
        </MenuButton>
        <MenuList
          py={1}
          minW={60}
          color="initial"
          overflowY="auto"
          borderRadius="md"
          zIndex="dropdown"
          borderWidth={1.5}
          bg="var(--ppl-colors-chakra-body-bg)">
          <MenuItem
            mb={1}
            py={0}
            as="div"
            cursor="default"
            paddingInline={2}
            alignItems="center"
            justifyContent="center"
            _focus={{ bg: 'none' }}
            transition="background-color 0.2s"
            _hover={{ bg: 'none', cursor: 'default' }}>
            <Box display="grid" gridTemplateColumns="repeat(3,1fr)">
              {appModuleLinks.map((item, index) => (
                <QuickAccessLink key={index} path={item.path} label={item.label} icon={item.icon} />
              ))}
            </Box>
          </MenuItem>
          <MenuDivider />
          <MenuItem p={0} my={1} paddingInline={0} _hover={{ bg: 'none' }}>
            <Flex
              mx={2}
              py={1}
              minH={8}
              w="100%"
              flexGrow={1}
              alignItems="center"
              justifyItems="center"
              transition="background-color 0.2s"
              _hover={{ borderRadius: 'md', bg: 'gray.100' }}>
              <Flex
                px={2}
                flexGrow={1}
                fontWeight="normal"
                alignItems="center"
                justifyItems="center">
                <Flex margin="-2px 10px -2px -2px" alignItems="center" justifyItems="center">
                  <Icon as={RiNotification3Line} w={5} h={5} color="inherit" />
                </Flex>
                <Box pr={1} fontSize="sm">
                  Notifications
                </Box>
              </Flex>
            </Flex>
          </MenuItem>

          <MenuItem mb={1} paddingInline={0} p={0} _hover={{ bg: 'none' }}>
            <Flex
              mx={2}
              py={1}
              minH={8}
              w="100%"
              flexGrow={1}
              alignItems="center"
              transition="background-color 0.2s"
              _hover={{ borderRadius: 'md', bg: 'gray.100' }}>
              <Flex px={2} flexGrow={1} alignItems="center" fontWeight="normal">
                <Flex margin="-2px 10px -2px -2px;" alignItems="center" justifyItems="center">
                  <Icon as={FiHelpCircle} w={5} h={5} color="inherit" />
                </Flex>
                <Box pr={1} fontSize="sm">
                  Help
                </Box>
              </Flex>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
