import { AxiosError } from 'axios';

import { THttpResponse } from '@people/ui/api';

const getMutationErrorFn = (data: unknown, error?: Error | null) => {
  if (error !== null && error !== undefined) {
    return error.message;
  }
  if (data instanceof AxiosError) {
    const res = data.response?.data;
    return typeof res === 'string' ? res : res?.error ?? res?.detail ?? data.message;
  }
  if (data instanceof Error) {
    return data.message;
  }
  if ((data as THttpResponse<any>)?.errors) {
    return (data as THttpResponse<any>).errors;
  }
  return null;
};

export const getMutationError = (data: unknown, error?: Error | null) => {
  const mutationError = getMutationErrorFn(data, error);
  if (mutationError) {
    if (Array.isArray(mutationError)) {
      return 'An error occurred';
    }
    if (typeof mutationError !== 'string') {
      return JSON.stringify(mutationError);
    }
  }
  return mutationError;
};
