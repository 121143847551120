import { AiOutlineMoneyCollect } from 'react-icons/ai';
import { HiPresentationChartLine } from 'react-icons/hi';

import { Icon } from '@chakra-ui/react';

import { LogoWhiteBackground } from '@people/ui/assets';
import { AppPath } from '@people/ui/shared/enums';

import { TAppModuleLink, TGlobalState, TSEOProps } from './interfaces';

/**
 * @public
 */
export const AppName = 'WIOCC People';

/**
 * @public
 */

export const globalProviderError =
  'GlobalProvider context is undefined, please verify you are calling useAuth() as child of a <GlobalProvider> component.';

/**
 * @public
 */

export const initSEO: TSEOProps = {
  title: 'WIOCC People',
  description: 'A platform for managing employee information and company resources.',
  canonicalUrl: window.location.href,
  ogTitle: 'WIOCC Group',
  ogDescription:
    'WIOCC Group is the parent company to digital infrastructure provider, WIOCC, and award-winning data centre operator, Open Access Data Centres',
  ogImageUrl: LogoWhiteBackground,
  twitterTitle: 'WIOCC People',
  twitterDescription: 'A platform for managing employee information and company resources.',
  twitterImageUrl: LogoWhiteBackground
};

/**
 * @public
 */

export const initGlobalState: TGlobalState = {
  seo: initSEO,
  alter: undefined,
  sidebarOpen: true,
  currencies: undefined,
  claimCategories: undefined,
  myClaimStat: undefined
};

/**
 * @public
 */

export const appModuleLinks: TAppModuleLink[] = [
  {
    label: 'Claims',
    path: AppPath.CLAIMS,
    icon: <Icon as={AiOutlineMoneyCollect} w={5} h={5} color="inherit" />
  },
  {
    label: 'PDG',
    path: AppPath.PDG,
    icon: <Icon as={HiPresentationChartLine} w={5} h={5} color="inherit" />
  }
];
