import React from 'react';

import { Box } from '@mui/material';

import { CircularSpinner } from './CircularSpinner';

export const LoadingResource: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        weight: '100%'
      }}>
      <Box
        height="50px"
        width="150px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        bgcolor="white"
        borderRadius="4px"
        padding="1rem">
        <Box width="40%" margin="0 auto">
          <CircularSpinner />
        </Box>
      </Box>
    </Box>
  );
};
