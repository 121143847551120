import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import { AppPath } from './enums';

const Login = React.lazy(() => import('@people/ui/auth/pages/Login'));
const MainDashboard = React.lazy(() => import('@people/ui/dashboard/pages/MainDashboard'));
const ClaimsDashboard = React.lazy(() => import('@people/ui/claims/pages/Dashboard'));
const SubmitClaim = React.lazy(() => import('@people/ui/claims/pages/SubmitClaim'));
const NotFound = React.lazy(() => import('@people/ui/shared/pages/NotFound'));
const AdminReports = React.lazy(() => import('@people/ui/claims/pages/AdminReports'));
const ClaimReports = React.lazy(() => import('@people/ui/claims/pages/ClaimReports'));
const DraftedClaims = React.lazy(() => import('@people/ui/claims/pages/DraftedClaims'));
const HRApproval = React.lazy(() => import('@people/ui/claims/pages/HRApproval'));
const HRReports = React.lazy(() => import('@people/ui/claims/pages/HRReports'));
const SupervisorApproval = React.lazy(() => import('@people/ui/claims/pages/SupervisorApproval'));
const SupervisorReports = React.lazy(() => import('@people/ui/claims/pages/SupervisorReports'));
const SingleClaim = React.lazy(() => import('@people/ui/claims/pages/SingleClaim'));
const EditClaim = React.lazy(() => import('@people/ui/claims/pages/EditClaim'));

export const routing = createBrowserRouter([
  { path: AppPath.ROOT, element: <Navigate to={AppPath.HOME} /> },
  { path: AppPath.LOGIN, element: <Login /> },
  { path: AppPath.HOME, element: <MainDashboard /> },
  { path: AppPath.CLAIMS, element: <ClaimsDashboard /> },
  { path: `${AppPath.CLAIMS}/:id`, element: <SingleClaim /> },
  { path: AppPath.SUBMIT_CLAIM, element: <SubmitClaim /> },
  { path: AppPath.DRAFTED_CLAIMS, element: <DraftedClaims /> },
  { path: `${AppPath.DRAFTED_CLAIMS}/:id`, element: <SingleClaim /> },
  { path: `${AppPath.DRAFTED_EDITING_CLAIM}/:id`, element: <EditClaim /> },
  { path: AppPath.CLAIM_REPORTS, element: <ClaimReports /> },
  { path: `${AppPath.CLAIM_REPORTS}/:id`, element: <SingleClaim /> },
  { path: AppPath.SUPERVISOR_APPROVAL_CLAIMS, element: <SupervisorApproval /> },
  { path: `${AppPath.SUPERVISOR_APPROVAL_CLAIMS}/:id`, element: <SingleClaim /> },
  { path: AppPath.SUPERVISOR_CLAIM_REPORTS, element: <SupervisorReports /> },
  { path: `${AppPath.SUPERVISOR_CLAIM_REPORTS}/:id`, element: <SingleClaim /> },
  { path: AppPath.HR_APPROVAL_CLAIMS, element: <HRApproval /> },
  { path: `${AppPath.HR_APPROVAL_CLAIMS}/:id`, element: <SingleClaim /> },
  { path: AppPath.HR_CLAIM_REPORTS, element: <HRReports /> },
  { path: `${AppPath.HR_CLAIM_REPORTS}/:id`, element: <SingleClaim /> },
  { path: AppPath.ADMIN_CLAIM_REPORTS, element: <AdminReports /> },
  { path: `${AppPath.ADMIN_CLAIM_REPORTS}/:id`, element: <SingleClaim /> },
  { path: AppPath.WILDCARD, element: <NotFound /> }
]);
