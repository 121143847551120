import { Field } from 'formik';
import React from 'react';

import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

type TFormInputProps = {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  onChange?: (value: string | number | unknown) => void;
};

export const FormInput: React.FC<TFormInputProps> = ({
  disabled,
  label,
  name,
  placeholder,
  onChange
}) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => (
        <FormControl
          gap={2}
          width="100%"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel fontWeight={600} fontSize="sm">
            {label}
          </FormLabel>
          <Input
            {...field}
            px={4}
            py={2}
            type="text"
            fontSize="sm"
            borderWidth={1}
            borderRadius="md"
            disabled={disabled}
            borderColor="gray.300"
            placeholder={placeholder}
            _invalid={{ borderColor: 'red.500' }}
            _disabled={{ bg: 'gray.100', cursor: 'not-allowed' }}
            onChange={(e) => {
              form.setFieldValue(name, e.target.value);
              if (onChange) {
                onChange(e.target.value);
              }
            }}
          />
          <FormErrorMessage color={form.errors[name] && 'red.500'}>
            {form.errors[name]}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
