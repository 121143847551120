import {
  Box,
  Button,
  type ColorProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Text,
  type ThemeTypings,
  type UseDisclosureProps
} from '@chakra-ui/react';

interface ReusableModalProps extends ModalProps {
  title?: string;
  altText?: string;
  okText?: string;
  okHandler: () => void;
  closeHandler?: () => void;
  isProcessing: boolean;
  disclosure?: UseDisclosureProps;
  hideFooter?: boolean;
  hideAltBtn?: boolean;
  okBg?: ColorProps['color'];
  altBg?: ColorProps['color'];
  okColorScheme?: ThemeTypings['colorSchemes'];
  okClassName?: string;
  cancelClassName?: string;
}

export const ReusableModal = (props: ReusableModalProps) => {
  const {
    title,
    altText,
    okText,
    children,
    okHandler,
    closeHandler,
    isProcessing,
    // size = '6xl',
    hideAltBtn = false,
    hideFooter = false,
    scrollBehavior = 'inside',
    // okBg,
    okColorScheme,
    // altBg,
    okClassName,
    cancelClassName,
    ...modalProps
  } = props;

  return (
    <Modal
      isCentered={true}
      closeOnOverlayClick={false}
      size={{ md: '3xl', sm: 'full', base: 'full' }}
      scrollBehavior={scrollBehavior}
      {...modalProps}>
      <ModalOverlay />
      <ModalContent paddingInline={0} borderRadius="md">
        <ModalHeader padding={2} borderBottomWidth="1px">
          <Box paddingTop={2} paddingBottom={1}>
            <Text as="h3" fontSize={{ base: 'sm', md: 'md' }}>
              {title ?? 'Modal Title'}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton className="hub-modal--btn" />
        <ModalBody maxH="90%" overflow="auto" paddingInline={0} padding={2}>
          {children ?? 'body content goes here'}
        </ModalBody>

        {!hideFooter && (
          <ModalFooter paddingInline={0} padding={2} borderTopWidth="1px">
            {!hideAltBtn && (
              <Button
                justifyContent="center"
                p={2}
                alignItems="center"
                fontSize="xs"
                borderRadius="md"
                fontWeight="semibold"
                bg="gray.400"
                color="white"
                height="30px"
                _hover={{
                  bg: 'gray.500'
                }}
                mr={3}
                onClick={() => {
                  if (closeHandler !== undefined) {
                    closeHandler();
                  }
                  modalProps.onClose();
                }}
                isLoading={isProcessing}
                disabled={isProcessing}
                hidden={hideAltBtn}>
                {altText ?? 'Cancel'}
              </Button>
            )}
            <Button
              isLoading={isProcessing}
              onClick={okHandler}
              justifyContent="center"
              p={2}
              alignItems="center"
              fontSize="xs"
              borderRadius="md"
              fontWeight="semibold"
              bg="green.400"
              color="white"
              height="30px"
              _hover={{
                bg: 'green.500'
              }}
              colorScheme={okColorScheme}
              mx={hideAltBtn ? 'auto' : 0}>
              {okText ?? 'Ok'}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
