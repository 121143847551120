import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Flex } from '@chakra-ui/react';

import { AppName, initSEO } from '@people/ui/shared/constants';
import { TGlobalContextProps } from '@people/ui/shared/contexts';
import { GlobalReducerActionType } from '@people/ui/shared/enums';
import { useGlobalState } from '@people/ui/shared/hooks/useGlobalState';
import { TSEOProps } from '@people/ui/shared/interfaces';

/**
 * @public
 */

export type TWithAuthProps = {} & TSEOProps;

/**
 * @public
 */

export function withHelmet<P>(
  Component: React.ComponentType<P>,
  options: TWithAuthProps = { title: 'Loading...' }
): React.ComponentType<Omit<P, keyof TGlobalContextProps>> {
  const displayName = `withHelmet(${Component.displayName || Component.name})`;
  const C: React.FC<Omit<P, keyof TGlobalContextProps>> = (props) => {
    const { globalDispatch, seo } = useGlobalState();

    React.useEffect(() => {
      globalDispatch({
        type: GlobalReducerActionType.SET_SEO,
        seo: {
          ...initSEO,
          ...options,
          title: `${AppName} | ${options.title}`,
          twitterTitle: `${AppName} | ${options.title}`
        }
      });
    }, [globalDispatch]);

    return (
      <Flex flexDirection="column" flexGrow={1} overflow="hidden">
        <Helmet prioritizeSeoTags>
          <title>{seo.title}</title>
          <link rel="canonical" href={seo.canonicalUrl} />
          <meta name="description" content={seo.description} />
          <meta property="og:title" content={seo.ogTitle} />
          <meta property="og:description" content={seo.ogDescription} />
          <meta property="og:image" content={seo.ogImageUrl} />
          <meta name="twitter:title" content={seo.twitterTitle} />
          <meta name="twitter:title" content={seo.description} />
          <meta name="twitter:image" content={seo.twitterImageUrl} />
        </Helmet>
        <Component {...(props as P)} seo={seo} />
      </Flex>
    );
  };

  C.displayName = displayName;

  return C;
}
