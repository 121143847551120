import React from 'react';
import { Link } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';

type NavBarLinkProps = {
  path: string;
  children: React.ReactNode;
};

export const NavBarLink: React.FC<NavBarLinkProps> = ({ children, path }) => {
  return (
    <Flex
      mx={1}
      gap={1}
      as={Link}
      to={path}
      height={8}
      fontSize="sm"
      paddingInline={3}
      borderRadius="md"
      position="relative"
      whiteSpace="nowrap"
      alignItems="center"
      fontWeight="semibold"
      justifyContent="center"
      border="1px solid transparent"
      transition="background-color 0.2s"
      _hover={{ bg: 'rgba(255, 255, 255, 0.1)' }}>
      {children}
    </Flex>
  );
};
