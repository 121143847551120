import { Link } from 'react-router-dom';

import { Box, Flex } from '@chakra-ui/react';

type QuickAccessLinkProps = {
  label: string;
  path: string;
  image?: string;
  icon?: React.ReactNode;
};

export const QuickAccessLink: React.FC<QuickAccessLinkProps> = ({ label, path, icon }) => {
  return (
    <Flex
      py={2}
      px={2}
      as={Link}
      to={path}
      cursor="pointer"
      borderRadius="md"
      alignItems="center"
      flexDirection="column"
      backgroundColor="transparent"
      transition="background-color 0.2s"
      _hover={{ backgroundColor: 'gray.100' }}>
      <Flex
        h={14}
        w={14}
        mb={2}
        borderWidth="1px"
        borderRadius="md"
        alignItems="center"
        justifyContent="center"
        backgroundColor="white">
        {icon}
      </Flex>
      <Box fontSize="sm">{label}</Box>
    </Flex>
  );
};
