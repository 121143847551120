import React from 'react';
import { BiLogOut } from 'react-icons/bi';
import { FaRegCircleUser } from 'react-icons/fa6';
import { FiHelpCircle, FiSettings } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList
} from '@chakra-ui/react';

import { useAuth } from '@people/ui/auth';
import { AppPath } from '@people/ui/shared/enums';
import { userInitials } from '@people/ui/shared/utils';

export const NavBarUserMenu: React.FC = () => {
  const { user, logoutUser } = useAuth();

  return (
    <Box role="presentation" position="relative" bg="initial">
      <Menu>
        <MenuButton
          h={8}
          w={8}
          padding={0}
          borderRadius="md"
          paddingInline={0}
          alignItems="center"
          justifyContent="center"
          transition="background-color 0.2s"
          _hover={{ bg: 'rgba(255, 255, 255, 0.1)' }}
          sx={{
            '& span': {
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}>
          <Flex
            h={6}
            width={6}
            fontSize="xs"
            borderRadius="50%"
            alignItems="center"
            bg="rgb(255, 167, 39)"
            justifyContent="center"
            textTransform="uppercase">
            <Box
              w="10px"
              h="10px"
              top="auto"
              right="2px"
              bottom="1px"
              borderRadius="50%"
              position="absolute"
              bg="rgb(51, 160, 105)"
              border="2px solid rgb(46, 55, 84)"
            />
            {userInitials(user?.name)}
          </Flex>
        </MenuButton>
        <MenuList
          py={1}
          minW={60}
          color="initial"
          overflowY="auto"
          borderRadius="md"
          zIndex="dropdown"
          borderWidth={1.5}
          bg="var(--ppl-colors-chakra-body-bg)">
          <MenuItem
            mb={1}
            py={0}
            pb={1}
            gap={3}
            as="div"
            cursor="default"
            paddingInline={2}
            _focus={{ bg: 'none' }}
            _hover={{ bg: 'none', cursor: 'default' }}>
            <Box>
              <Box position="relative">
                <Flex
                  h={7}
                  w={7}
                  color="white"
                  fontSize="sm"
                  borderRadius="50%"
                  alignItems="center"
                  bg="rgb(255, 167, 39)"
                  justifyContent="center">
                  <Box
                    w={2}
                    h={2}
                    top="1px"
                    right="2px"
                    bottom="auto"
                    borderRadius="50%"
                    position="absolute"
                    bg="rgb(51, 160, 105)"
                    border="2px solid #fff"
                  />
                  {userInitials(user?.name)}
                </Flex>
              </Box>
            </Box>
            <Flex flexDirection="column" gap="px">
              <Box fontSize="sm" fontWeight="semibold">
                {user?.name}
              </Box>
              <Box fontSize="xs">{user?.job_title}</Box>
            </Flex>
          </MenuItem>
          <MenuDivider />
          <MenuItem
            p={0}
            my={1}
            as={Link}
            paddingInline={0}
            to={AppPath.PROFILE}
            _hover={{ bg: 'none' }}>
            <Flex
              mx={2}
              py={1}
              minH={8}
              w="100%"
              flexGrow={1}
              alignItems="center"
              justifyItems="center"
              transition="background-color 0.2s"
              _hover={{ borderRadius: 'md', bg: 'gray.100' }}>
              <Flex
                px={2}
                flexGrow={1}
                fontWeight="normal"
                alignItems="center"
                justifyItems="center">
                <Flex margin="-2px 10px -2px -2px" alignItems="center" justifyItems="center">
                  <Icon as={FaRegCircleUser} w={5} h={5} color="inherit" />
                </Flex>
                <Box pr={1} fontSize="sm">
                  Profile
                </Box>
              </Flex>
            </Flex>
          </MenuItem>
          <MenuItem
            as={Link}
            to={AppPath.SETTINGS}
            mb={1}
            paddingInline={0}
            p={0}
            _hover={{ bg: 'none' }}>
            <Flex
              mx={2}
              py={1}
              minH={8}
              w="100%"
              flexGrow={1}
              alignItems="center"
              justifyItems="center"
              transition="background-color 0.2s"
              _hover={{ borderRadius: 'md', bg: 'gray.100' }}>
              <Flex
                px={2}
                flexGrow={1}
                fontWeight="normal"
                alignItems="center"
                justifyItems="center">
                <Flex margin="-2px 10px -2px -2px" alignItems="center" justifyItems="center">
                  <Icon as={FiSettings} w={5} h={5} color="inherit" />
                </Flex>
                <Box pr={1} fontSize="sm">
                  Settings
                </Box>
              </Flex>
            </Flex>
          </MenuItem>
          <MenuDivider />
          <MenuItem my={1} paddingInline={0} p={0} _hover={{ bg: 'none' }}>
            <Flex
              mx={2}
              py={1}
              minH={8}
              w="100%"
              flexGrow={1}
              alignItems="center"
              justifyItems="center"
              transition="background-color 0.2s"
              _hover={{ borderRadius: 'md', bg: 'gray.100' }}>
              <Flex
                px={2}
                flexGrow={1}
                fontWeight="normal"
                alignItems="center"
                justifyItems="center">
                <Flex margin="-2px 10px -2px -2px" alignItems="center" justifyItems="center">
                  <Icon as={FiHelpCircle} w={5} h={5} color="inherit" />
                </Flex>
                <Box pr={1} fontSize="sm">
                  Help
                </Box>
              </Flex>
            </Flex>
          </MenuItem>
          <MenuDivider />
          <MenuItem my={1} paddingInline={0} p={0} _hover={{ bg: 'none' }}>
            <Flex
              mx={2}
              py={1}
              minH={8}
              w="100%"
              flexGrow={1}
              alignItems="center"
              justifyItems="center"
              onClick={() => logoutUser()}
              transition="background-color 0.2s"
              _hover={{ borderRadius: 'md', bg: 'gray.100' }}>
              <Flex
                px={2}
                flexGrow={1}
                fontWeight="normal"
                alignItems="center"
                justifyItems="center">
                <Flex margin="-2px 10px -2px -2px" alignItems="center" justifyItems="center">
                  <Icon as={BiLogOut} w={5} h={5} color="inherit" />
                </Flex>
                <Box pr={1} fontSize="sm">
                  Logout
                </Box>
              </Flex>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
