/**
 * @public
 */

export enum GlobalReducerActionType {
  SET_SEO = 'SET_SEO',
  SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN',
  SET_CURRENCIES = 'SET_CURRENCIES',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_MY_CLAIM_STAT = 'SET_MY_CLAIM_STAT'
}

/**
 * @public
 */

export const APIEndpoint = {
  AUTH_URL: 'auth/url?redirect_to=',
  AUTH_USER: 'auth/user',
  AUTH_LOGIN: 'auth/login',
  AUTH_LOGOUT: 'auth/logout'
};

/**
 * @public
 */

export enum AppPath {
  ROOT = '/',
  HOME = '/home',
  LOGIN = '/login',
  LOGOUT = '/logout',
  CLAIMS = '/claims',
  SUBMIT_CLAIM = '/claims/submit',
  DRAFTED_CLAIMS = '/claims/drafted',
  DRAFTED_EDITING_CLAIM = '/claims/drafted/editing',
  CLAIM_REPORTS = '/claims/reports',
  SUPERVISOR_APPROVAL_CLAIMS = '/claims/supervisor/approval',
  SUPERVISOR_CLAIM_REPORTS = '/claims/supervisor/reports',
  HR_APPROVAL_CLAIMS = '/claims/hr/approval',
  HR_CLAIM_REPORTS = '/claims/hr/reports',
  ADMIN_CLAIM_REPORTS = '/claims/admin/reports',
  USERS = '/users',
  PROFILE = '/profile',
  SETTINGS = '/settings',
  PDG = '/pdg',
  WILDCARD = '*'
}
