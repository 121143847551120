import React from 'react';
import { RiCloseLargeLine } from 'react-icons/ri';

import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure
} from '@chakra-ui/react';

import { TEncodedFileAttachment } from '../../interfaces';
import { FilePreview } from '../FilePreview';

type TFileItemsListProps = {
  files: TEncodedFileAttachment[];
};

export const FileItemsList: React.FC<TFileItemsListProps> = ({ files }) => {
  const selectedFilePreviewDisclosure = useDisclosure();
  const selectedFileForPreview = React.useRef<number | null>(null);

  return (
    <Flex flexGrow={1} flexDirection="column" p={2} bg="gray.50">
      <Flex flexGrow={1}>
        <SimpleGrid
          flexGrow={1}
          w="full"
          columns={{ sm: 2, md: 3 }}
          justifyContent="space-between"
          spacing={2}
          spacingY={3}
          overflow="auto"
          pb={5}>
          {files.map((file, index) => (
            <Flex
              key={index}
              w="100%"
              height="100%"
              flexGrow={1}
              borderWidth={1}
              borderColor="gray.200"
              borderRadius="sm"
              position="relative"
              p={1}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                selectedFileForPreview.current = index;
                selectedFilePreviewDisclosure.onOpen();
              }}>
              <FilePreview file={file} />
            </Flex>
          ))}
        </SimpleGrid>
      </Flex>
      {selectedFilePreviewDisclosure && files?.[selectedFileForPreview.current ?? 0] && (
        <Modal
          isOpen={selectedFilePreviewDisclosure.isOpen}
          onClose={selectedFilePreviewDisclosure.onClose}
          isCentered={true}
          size="full"
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent paddingInline={0} borderRadius="md" bg="var(--ppl-colors-chakra-body-bg)">
            <ModalHeader
              px={4}
              h={12}
              as={Flex}
              flexShrink={0}
              borderRadius="md"
              alignItems="center"
              borderBottomWidth={1}>
              <Flex
                w="100%"
                height={10}
                alignItems="center"
                flexDirection="row"
                position="relative"
                justifyContent="space-between">
                <Text as="h3" fontSize={{ base: 'sm', md: 'md' }} fontWeight={500}>
                  {files?.[selectedFileForPreview.current ?? 0].name ?? 'File'}
                </Text>
                <Flex
                  as={Button}
                  h={8}
                  w={8}
                  bg="gray.200"
                  borderRadius="md"
                  onClick={selectedFilePreviewDisclosure.onClose}
                  alignItems="center"
                  justifyContent="center"
                  _hover={{ bg: 'red.500', color: 'white' }}>
                  <Icon as={RiCloseLargeLine} w={5} h={5} color="inherit" />
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalBody
              as={Flex}
              flexDirection="column"
              flexGrow={1}
              overflow="auto"
              paddingInline={4}
              gap={4}
              py={2}>
              <FilePreview inSingleView={true} file={files[selectedFileForPreview.current || 0]} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};
