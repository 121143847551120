import React from 'react';

import { globalProviderError, initGlobalState } from '@people/ui/shared/constants';
import { GlobalContext, GlobalReducer, TGlobalContextProps } from '@people/ui/shared/contexts';
import { TGlobalReducerAction, TGlobalState } from '@people/ui/shared/interfaces';

/**
 * @public
 */

export const useGlobalState = (): TGlobalContextProps => {
  const context = React.useContext(GlobalContext);
  if (!context || context === undefined) {
    console.warn(globalProviderError);
  }
  return { ...context } as TGlobalContextProps;
};

/**
 * @public
 */

type TUseGlobalStateAndDispatchReturn = {
  globalState: TGlobalState;
  globalDispatch: React.Dispatch<TGlobalReducerAction>;
};

/**
 * @public
 */

export const useGlobalStateAndDispatch = (): TUseGlobalStateAndDispatchReturn => {
  const localState = localStorage.getItem('globalState');
  const presentState = JSON.parse(localState ?? JSON.stringify(initGlobalState));
  const [globalState, globalDispatch] = React.useReducer(
    GlobalReducer,
    presentState as TGlobalState
  );
  return { globalState, globalDispatch };
};
