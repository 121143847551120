import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, axiosHttp } from '@people/ui/api';
import { authUrlError } from '@people/ui/auth/constants';
import { useAuth } from '@people/ui/auth/hooks/useAuth';
import { TAuthUrlResponse } from '@people/ui/auth/interfaces';
import { APIEndpoint, AppPath } from '@people/ui/shared';

/**
 * @public
 */

export type TUseAuthUrlProps = {
  redirect: string;
};

/**
 * @public
 */

export type TUseAuthUrlReturn = {
  authUrl: string | undefined;
};

/**
 * @public
 */

export const useAuthUrl = (): TUseAuthUrlReturn => {
  const auth = useAuth();
  const [authUrl, setAuthUrl] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    const redirect_to =
      auth.redirect && auth.redirect.trim() !== AppPath.LOGIN ? auth.redirect : AppPath.HOME;

    if (authUrl !== undefined && authUrl.trim() !== '') {
      return;
    }
    if (authUrl === undefined || authUrl.trim() === '') {
      void (async (): Promise<void> => {
        let error: Error | null = null;
        let response:
          | THttpResponse<TAuthUrlResponse | undefined>
          | Error
          | AxiosError
          | string
          | null
          | undefined = undefined;
        try {
          response = await axiosHttp.get<THttpResponse<TAuthUrlResponse | undefined>>({
            url: `${APIEndpoint.AUTH_URL}${redirect_to}`
          });
          if (
            response === undefined ||
            response === null ||
            response instanceof Error ||
            response instanceof AxiosError ||
            response instanceof String
          ) {
            error = new Error(authUrlError);
            console.log('Use auth url response', error);
          } else {
            setAuthUrl(response.data.url.trim() !== '' ? response.data.url : undefined);
          }
        } catch (err: unknown) {
          error = new Error(err as any);
          const userFriendlyError = new Error(authUrlError);
          console.log('error', error);
          console.log('Use auth url error: ', userFriendlyError);
        }
      })();
    }
  }, [authUrl, auth.redirect]);

  return { authUrl };
};
