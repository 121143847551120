import { FileType } from '@wiocc-systems/wiocc-react-utils';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { RiCloseLargeLine } from 'react-icons/ri';
import { TiMinus } from 'react-icons/ti';

import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure
} from '@chakra-ui/react';

import { TUseFileDropZoneAttachments, useFileDropZoneAttachments } from '../../hooks';
import { FilePreview } from '../FilePreview';

export const FileDropZone: React.FC<TUseFileDropZoneAttachments> = ({ ...props }) => {
  const { onFilesAdded: onDrop } = useFileDropZoneAttachments({ ...props });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const selectedFilePreviewDisclosure = useDisclosure();
  const selectedFileForPreview = React.useRef<number | null>(null);

  return (
    <Flex flexGrow={1}>
      <Flex
        gap={2}
        flexGrow={1}
        flexDirection="column"
        {...getRootProps({ className: `dropzone ${props.files.length > 0 ? 'has-files' : ''}` })}
        cursor="pointer">
        <Box
          flexShrink={0}
          minHeight={8}
          className={`dropzone-input-wrapper ${props.files.length > 0 ? 'has-files' : ''}`}
          fontSize="xs"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          padding="1.5em">
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text>Drop the files here ...</Text>
          ) : (
            <Text>Drag &apos;n&apos; drop files here, or click to select files</Text>
          )}
        </Box>
        <Flex flexGrow={1}>
          <SimpleGrid
            flexGrow={1}
            w="full"
            columns={{ sm: 2, md: 3 }}
            justifyContent="space-between"
            spacing={2}
            spacingY={3}
            overflow="auto"
            pb={5}
            className="droppedfiles-grid">
            {props.files.map((file, index) => (
              <Flex
                key={index}
                w="100%"
                height="100%"
                flexGrow={1}
                borderWidth={1}
                borderColor="gray.200"
                borderRadius="sm"
                position="relative"
                p={1}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  selectedFileForPreview.current = index;
                  selectedFilePreviewDisclosure.onOpen();
                }}>
                <Flex
                  as={Button}
                  h={6}
                  w={8}
                  top="6px"
                  right={2}
                  zIndex={3}
                  position="absolute"
                  bg="gray.200"
                  borderRadius="md"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{ bg: 'red.500', color: 'white' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.setFiles((files) => files.filter((_, x) => x !== index));
                  }}>
                  <Icon as={TiMinus} w={3} h={3} color="inherit" />
                </Flex>
                <FilePreview file={file} />
              </Flex>
            ))}
          </SimpleGrid>
        </Flex>
      </Flex>
      {selectedFilePreviewDisclosure && props.files?.[selectedFileForPreview.current ?? 0] && (
        <Modal
          isOpen={selectedFilePreviewDisclosure.isOpen}
          onClose={selectedFilePreviewDisclosure.onClose}
          isCentered={true}
          size="full"
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent paddingInline={0} borderRadius="md" bg="var(--ppl-colors-chakra-body-bg)">
            <ModalHeader
              px={4}
              h={12}
              as={Flex}
              flexShrink={0}
              borderRadius="md"
              alignItems="center"
              borderBottomWidth={1}>
              <Flex
                w="100%"
                height={10}
                alignItems="center"
                flexDirection="row"
                position="relative"
                justifyContent="space-between">
                <Text as="h3" fontSize={{ base: 'sm', md: 'md' }} fontWeight={500}>
                  {props.files?.[selectedFileForPreview.current ?? 0].name ?? 'File'}
                </Text>
                <Flex
                  as={Button}
                  h={8}
                  w={8}
                  bg="gray.200"
                  borderRadius="md"
                  onClick={selectedFilePreviewDisclosure.onClose}
                  alignItems="center"
                  justifyContent="center"
                  _hover={{ bg: 'red.500', color: 'white' }}>
                  <Icon as={RiCloseLargeLine} w={5} h={5} color="inherit" />
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalBody
              as={Flex}
              flexDirection="column"
              flexGrow={1}
              overflow="auto"
              paddingInline={4}
              gap={4}
              py={2}>
              <FilePreview
                inSingleView={true}
                file={props.files[selectedFileForPreview.current || 0]}
                isPDF={props.files[selectedFileForPreview.current ?? 0]?.type === FileType.PDF}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};
