import React from 'react';

import { Flex } from '@chakra-ui/react';

import { NavBar } from './NavBar';

type TLayoutProps = {
  children: React.ReactNode;
};

export const Layout: React.FC<TLayoutProps> = ({ children }) => {
  return (
    <Flex flexGrow={1} flexDirection="column" h="100%" w="100%" overflow="hidden">
      <Flex flexGrow={1} flexDirection="column" h="100%" w="100%" overflow="hidden">
        <Flex
          as="header"
          role="navigation"
          flexDirection="column"
          flexShrink={0}
          position="relative"
          w="100%">
          <NavBar />
        </Flex>
        <Flex
          role="banner"
          flexDirection="column"
          flexShrink={0}
          position="relative"
          w="100%"></Flex>
        <Flex
          as="main"
          role="presentation"
          flexGrow={1}
          flexDirection="column"
          w="100%"
          overflow="hidden">
          {children}
        </Flex>
        <Flex as="footer" role="navigation" flexDirection="row" flexShrink={0} w="100%"></Flex>
      </Flex>
    </Flex>
  );
};
