/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';

export type TUseFileDropZoneAttachments = {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
};

export const useFileDropZoneAttachments = (props: TUseFileDropZoneAttachments) => {
  const addedFiles = React.useRef<Set<string>>(new Set());

  const onFilesAdded = React.useCallback(
    (acceptedFiles: File[]) => {
      props.setFiles((files) => {
        return acceptedFiles.reduce(
          (newList, file) => {
            const fileNameAndSize = `${file.name}_${file.size}`;
            // File does not already exist in list
            if (!addedFiles.current.has(fileNameAndSize)) {
              newList.push(file);
              addedFiles.current.add(fileNameAndSize);
            }
            return newList;
          },
          [...files]
        );
      });
    },
    [props]
  );

  return { addedFiles, onFilesAdded };
};
