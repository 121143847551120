import React from 'react';
import { BiLogOut } from 'react-icons/bi';
import { RiMenuFoldLine } from 'react-icons/ri';
import { TbHomeMove } from 'react-icons/tb';
import { useNavigate } from 'react-router';

import { Flex, Heading, Icon, Text, useBreakpointValue } from '@chakra-ui/react';

import { useAuth } from '@people/ui/auth';
import { Layout, PageHead } from '@people/ui/shared/components/Layout';
import { NavBarButton } from '@people/ui/shared/components/buttons';
import { AppPath } from '@people/ui/shared/enums';
import { useGlobalState } from '@people/ui/shared/hooks';

type TSidebarLayoutProps = {
  moduleTitle: string;
  pageHeadTitle: string;
  children: React.ReactNode;
  moduleIcon: React.ReactNode;
  pageHeadIcon: React.ReactNode;
  sidebarNavigation?: React.ReactNode;
  pageHeadActionsArea?: React.ReactNode;
};

export const SidebarLayout: React.FC<TSidebarLayoutProps> = ({
  children,
  moduleIcon,
  moduleTitle,
  pageHeadIcon,
  pageHeadTitle,
  sidebarNavigation,
  pageHeadActionsArea
}) => {
  const navigate = useNavigate();
  const { logoutUser } = useAuth();
  const { sidebarOpen } = useGlobalState();
  const isSmallScreens = useBreakpointValue({ base: true, md: false }, { ssr: false });

  return (
    <Layout>
      <Flex flexGrow={1} flexDirection="row" overflow="hidden">
        {!isSmallScreens && (
          <Flex
            as="aside"
            h="100%"
            bg="gray.50"
            flexShrink={0}
            overflow="hidden"
            position="relative"
            borderEndWidth={0.5}
            flexDirection="column"
            w={sidebarOpen && !isSmallScreens ? 64 : 12}
            transition="border-width 0.2s, width 0.2s, display 0.2s">
            <Flex
              w="100%"
              height={10}
              flexShrink={0}
              paddingInline={2}
              position="relative"
              borderBottomWidth={1}
              flexDirection="column">
              <Flex
                px={2}
                height={8}
                marginTop={1}
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between">
                <Flex gap={2} alignItems="center">
                  {moduleIcon}
                  <Heading
                    fontSize="md"
                    fontWeight="semibold"
                    display={!sidebarOpen && !isSmallScreens ? 'none' : 'blok'}>
                    {moduleTitle}
                  </Heading>
                </Flex>
                {isSmallScreens && (
                  <NavBarButton bgColor="gray.200" hoverBgColor="gray.300">
                    <Icon as={RiMenuFoldLine} w={5} h={5} color="inherit" />
                  </NavBarButton>
                )}
              </Flex>
            </Flex>
            <Flex
              role="navigation"
              flexGrow={1}
              mb="-1px"
              overflowY="auto"
              overflowX="hidden"
              flexDirection="column"
              scrollBehavior="smooth"
              transform="translateZ(0)"
              willChange="scroll-position">
              <Flex flexDirection="column" flexGrow={1} paddingInline={2} my={4}>
                {sidebarNavigation}
              </Flex>
            </Flex>
            <Flex
              py={1}
              gap={4}
              flexShrink={0}
              borderTopWidth={1}
              alignItems="center"
              paddingInline={!sidebarOpen && !isSmallScreens ? 2 : 4}>
              <Flex flexGrow={1}>
                <NavBarButton
                  bgColor="gray.200"
                  hoverBgColor="gray.300"
                  onClick={() => logoutUser()}
                  width={!sidebarOpen && !isSmallScreens ? undefined : '100%'}
                  paddingInline={!sidebarOpen && !isSmallScreens ? undefined : 3}
                  justifyContent={!sidebarOpen && !isSmallScreens ? 'center' : 'flex-start'}>
                  <Icon as={BiLogOut} w={5} h={5} color="inherit" />
                  <Text display={!sidebarOpen && !isSmallScreens ? 'none' : 'block'}> Logout</Text>
                </NavBarButton>
              </Flex>
              <Flex flexShrink={0} display={!sidebarOpen && !isSmallScreens ? 'node' : 'flex'}>
                <NavBarButton
                  onClick={() => navigate(AppPath.HOME)}
                  bgColor="gray.200"
                  hoverBgColor="gray.300">
                  <Icon as={TbHomeMove} w={5} h={5} color="inherit" />
                </NavBarButton>
              </Flex>
            </Flex>
          </Flex>
        )}
        <Flex as="main" role="presentation" flexGrow={1} flexDirection="column" overflow="hidden">
          <PageHead
            hasSidebar
            title={pageHeadTitle}
            icon={pageHeadIcon}
            actionsArea={pageHeadActionsArea}
          />
          <Flex flexGrow={1} flexDirection="column" overflow="hidden">
            {children}
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};
